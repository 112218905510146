import { Link } from "gatsby";
import React from "react";

export default function misc() {
  return (
    <>
      <section className="block">
        <ul className="breadcrumb">
          <li>
            <Link to="/">&#8962; Home</Link>
          </li>
        </ul>
      </section>
      <section className="block absolute-bottom">
        <ul className="big-link-list">
          <li>
            <Link to="/seoul/1337006525008003919/">Ryse</Link>
          </li>
          <li>
            <Link to="/jeju-do/hotel-regent-marine-jeju/">Regent Marine</Link>
          </li>
          <li>
            <Link to="/jeju-do/10325014446385908655/">Playce Camp</Link>
          </li>
          <li>
            <Link to="/seoul/3465118122475842922/">Hotel 28</Link>
          </li>
          <li>
            <Link to="/allergy/">Allergy</Link>
          </li>
        </ul>
      </section>
    </>
  );
}
